import { Link, graphql } from 'gatsby';
import type { FC } from 'react';
import styled from 'styled-components';
import { PageFrame } from '../components/PageFrame';
import { Colors } from '../utils/Colors';

const Bio = styled.section`
  padding-bottom: 1rem;
  border-bottom: 1px solid ${Colors.Border};
`;

const ProjectList = styled.ul`
  list-style: none;
`;

const ProjectPreview = styled.li`
  small {
    color: ${Colors.Border};
  }
`;

type IndexProps = {
  data: GatsbyTypes.IndexQueryQuery;
};

export const Index: FC<IndexProps> = ({
  data: { allContentfulProject, site },
}) => {
  return (
    <PageFrame
      description={site?.siteMetadata?.description}
      title={site?.siteMetadata?.title}
    >
      <h1>Hello.</h1>

      <Bio>
        My name is Risto Keravuori. I live in Laguna Beach, California, and
        currently work remotely for <a href="https://m.twitch.tv">Twitch</a> as
        a lead engineer on their Emerging Platforms team.
      </Bio>

      <section>
        <h2>Recent stuff I&apos;ve been working on</h2>

        <ProjectList>
          {allContentfulProject.edges.map(({ node }) => (
            <ProjectPreview key={node.id}>
              <h3>
                <Link to={node.gatsbyPath ?? '/projects'}>{node.title}</Link>{' '}
                <small>&mdash; launched {node.launchDate?.toLowerCase()}</small>
              </h3>
            </ProjectPreview>
          ))}
        </ProjectList>
        <Link to="/projects">See more projects</Link>
      </section>
    </PageFrame>
  );
};
Index.displayName = 'Index';
export default Index;

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        description
        title
      }
    }
    allContentfulProject(limit: 3, sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          id
          gatsbyPath(filePath: "/projects/{ContentfulProject.slug}")
          title
          launchDate(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`;
